<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<div
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</div>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
				<b-button
					class="float-right"
					variant="primary"
					@click="downloadExcel">
					<i class="fas fa-file-excel" /> {{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="month"
								class="label">{{ translate('month') }}</label>
							<select
								id="month"
								v-model="selectedMonth"
								name="month"
								class="form-control">
								<option
									v-for="index in 12"
									:key="index"
									:value="index">
									{{ $moment(index,'MM').format('MMMM') }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="year"
								class="label">{{ translate('year') }}</label>
							<select
								id="year"
								v-model="selectedYear"
								name="year"
								class="form-control">
								<option
									v-for="year in years"
									:key="year"
									:value="year">
									{{ year }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<router-view />
			</b-col>
		</b-row>
	</div>
</template>
<script>

import {
	Report, Grids, Countries, Dates,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import {
	MDY_FORMAT,
	TAXES_START_YEAR,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Taxes from '@/util/Report';

export default {
	name: 'Taxes',
	messages: [Report, Grids, Countries, Dates],
	components: { DataFilter },
	mixins: [FiltersParams],
	data() {
		return {
			selectedMonth: this.$moment().month(),
			selectedYear: this.$moment().year(),
			taxes: new Taxes(),
		};
	},
	computed: {
		loading() {
			return !!this.taxes.data.loading;
		},
		data() {
			try {
				const { response } = this.taxes.data.response.data;
				return response;
			} catch (error) {
				return [];
			}
		},
		rangeStart() {
			try {
				return this.$moment(this.data.period_dates.start.date).locale(this.language).format(MDY_FORMAT);
			} catch (error) {
				return null;
			}
		},
		rangeEnd() {
			try {
				return this.$moment(this.data.period_dates.end.date).locale(this.language).format(MDY_FORMAT);
			} catch (error) {
				return null;
			}
		},
		datePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== null && end !== null) {
				return this.translate('date_period', { start, end });
			}

			if (start !== null) {
				return this.translate('date_period_from', { start });
			}

			if (end !== null) {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		query() {
			return this.$route.query;
		},
		years() {
			const startingYear = TAXES_START_YEAR;
			const endingYear = this.$moment().year();
			const years = [];
			for (let index = startingYear; index <= endingYear; index += 1) {
				years.push(index);
			}
			return years;
		},
	},
	mounted() {
		const urlParams = this.$route.query;
		if (typeof urlParams !== 'undefined') {
			if (urlParams.month) this.selectedMonth = urlParams.month;
			if (urlParams.year) this.selectedYear = urlParams.year;
		}

		this.taxes.getTaxesSummary();
		this.getDataFiltered();

		return null;
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: 'TaxesAll', query: options }).catch(() => {});
		},
		downloadExcel() {
			const taxes = new Taxes();
			const loading = new this.$Alert();
			loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });

			taxes.downloadTaxReportExcel().then(() => {
				const { response } = taxes.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.$moment(this.selectedMonth, 'MM').format('MMMM')} ${this.selectedYear} - ${this.translate('sales_report')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				loading.close();
			}).catch(() => {
				loading.close();
			});
		},
	},
};
</script>
